<template>
    <div class="grid-container">
        <div class="grid grid-x">
            <div class="cell small-12">
                <div class="c-expiry-timer">
                    <p v-if="formattedTime > '00:00'">
                        <i class="c-expiry-timer__icon fa-sharp fa-light fa-clock"></i>

                        <b class="c-expiry-timer__time">{{ formattedTime }} </b>minutes left to purchase
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
const booking = useBookingStore()
const searchStore = useSearchStore();
const props = defineProps({
    sessionStart: {
        type: String,
        required: true,
    },
});

const timeRemaining = ref(0);
const elapsedSeconds = ref(0);

const formattedTime = computed(() => {
    const minutes = Math.floor(timeRemaining.value / 60);
    const seconds = timeRemaining.value % 60;
    return `${minutes.toString().padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
});

let timerInterval;

const refreshProvisionalBooking = async () => {
	console.log("Refreshing provisional booking...");

	const selectedResult = booking.order.selected_result;
	const echoToken = booking.order.guest_session;

	console.log(selectedResult);

	const guests = searchStore.data.selected_guests.filter(
		(guest) => guest.guest_id !== null
	);

	const response = await useFetch(
		"/api/booking/CreateProvisionalBookingFromAvailabilityResult",
		{
			method: "POST",
			body: {
				echoToken,
				availability: selectedResult,
				parkID: selectedResult.parkID,
				AgeGroupsList: guests,
				source: { ID: 1, Description: "Online" },
			},
		}
	);

	if (response.data) {
		console.log(response.data, "Provisional booking refreshed at", new Date().toLocaleTimeString());
	} else {
		console.error("Failed to refresh provisional booking");
	}
};



onMounted(async() => {
    const sessionStartTime = new Date(props.sessionStart).getTime();
    const endTime = sessionStartTime + 15 * 60 * 1000;


    const updateTimer = async () => {
        const currentTime = new Date().getTime();
        const remaining = Math.max(0, Math.floor((endTime - currentTime) / 1000));
        timeRemaining.value = remaining;

        elapsedSeconds.value += 1;

        if (remaining === 0) {
            clearInterval(timerInterval);
            console.log('Timer expired: clearing booking');
            booking.order.ProvisionalBookings = {};
            booking.order.selected = {};
            booking.order.extras = null;
        }

        // Refresh every 3 minutes (180s), only up to 12 minutes (720s)
        if (
            elapsedSeconds.value <= 720 &&
            elapsedSeconds.value % 180 === 0 &&
            remaining > 0
        ) {
            await refreshProvisionalBooking();
        }
    };

    updateTimer();
    timerInterval = setInterval(updateTimer, 1000);
});

onUnmounted(() => {
    clearInterval(timerInterval);
});

</script>
